@HomeDeveloper-primary: #4F90C3;
@HomeDeveloper-primary-transparent: #4f91c3f1;
@HomeDeveloper-primary-text: #4568dc;
@HomeDeveloper-secondary: #e9e9e9;
@HomeDeveloper-secondary-text: #e9e9e9;
@HomeDeveloper-tertiary: #131d3db6;
@HomeDeveloper-about: black;
@HomeDeveloper-text-hover: black;
@HomeDeveloper-tertiary-text: #aa10be;

@font-face {
  font-family: 'Avenir Black';
  src: local('Avenir Black'),
       url('../fonts/Avenir-Black.otf') format('opentype');
}
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'),
       url('../fonts/Oswald-Variable.ttf') format('truetype');
}
@font-face {
  font-family: 'Avenir Book';
  src: local('Avenir Book'),
       url('../fonts/Avenir-Book.otf') format('opentype');
}
@font-face {
  font-family: 'Avenir Roman';
  src: local('Avenir Roman'),
       url('../fonts/Avenir-Roman.otf') format('opentype');
}

@keyframes aumentaBrilho {
  0% {
    filter: blur(0px) drop-shadow(0px 0px 0px rgb(255, 247, 200));
  }
  100% {
    filter: blur(0px) drop-shadow(0px 0px 6px rgb(255, 247, 200));
  }
}

@keyframes aumentaOpacidade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.HomeDeveloper {
  min-height: 100%;
  font-family: 'Avenir Roman';
  font-weight: 500;

  .bgimg{
    position: absolute;
    filter: blur(20px);
    -webkit-filter: blur(20px);

    /* Full height */
    height: 110%;
    width: 110%;
    transform: translate3d(-5%,-5%,0);
    z-index: -1;
    top: 0px;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120% auto;
  }

  .mobile{
    .bgimg{
      background-size: auto 120%;
    }
  }

  .block{
    padding-top: 12vh;
    padding-bottom: 2vh;
    box-shadow: 0px 0px 20px black;
  }

  .block{
    .block-content{

      .subblock{

        h1{
          text-transform: uppercase;
        }
      }
    }
  }

  

  .HomeDeveloper-header {
    position: fixed;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 10vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 9998;
    transition: top ease 1s, height 1s ease;

    .logo{
      padding: 1vh;
      position: absolute;
      left: 2.5%;
      display: flex;

      img {
        height: 8vh;
        transform: translateY(-0vh);
      }
      span{
        color: @HomeDeveloper-secondary;
        line-height: 8vh;
        font-weight: 506;
        font-size: 1.3em;
        margin-left: 10px;
        font-family: "Oswald";
        color: white;
      }
    }

    nav{
      position: absolute;
      right: 2.5%;
      top: 0;
      display: flex;

      a, span {
        color: @HomeDeveloper-secondary-text;
        margin: 0px 10px;
        line-height: 10vh;
        font-size: large;
        font-weight: 500;
        transition: text-shadow .25s ease;
        text-decoration-color: transparent;
      }

      a:hover, span:hover{
        text-shadow: 0px 0px 5px rgb(253, 255, 141);
        color: @HomeDeveloper-secondary-text;
        cursor: pointer;
      }
    }

    &.mobile{

      right: 0;

      overflow: hidden;

      &.open{
        height: 100%;
      }

      nav{
        right: 0;
        padding: 0 2.5%;
      }

      nav.menu{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 10vh;

        a, span{
          padding-left: 5vw;
          height: 7vh;
          line-height: 7vh;
          text-align: center;
        }
      }
    }
  }

  .HomeDeveloper-header.esconder{
    top: -20vh;
  }

  .content{
    min-height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    color: @HomeDeveloper-secondary;

    .block{
      min-height: 100%;
      max-height: 100%;
      overflow: hidden;
      position: relative;

      @media (max-width: 700px){
        min-height: 100%;
        max-height: 200%;
      }

      .row{
        min-height: 100%;
        max-height: 100%;

        @media (max-width: 700px){
          min-height: 100%;
          max-height: 200%;
        }

        .subblock{
          min-height: 100%;
          max-height: 100%;

          .detail{
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            padding: 20px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
      
            h1{
              font-size: clamp(18px, 2.4vw, 30px);
              margin-bottom: 1vh;
            }
            h2{
              font-size: clamp(16px, 1.8vw, 25px);
              margin-bottom: 1vh;
            }
            p{
              font-size: clamp(14px, 1.2vw, 18px);
            }
          }
        }
      }
    }

    .intro{
      text-shadow: 3px 3px @HomeDeveloper-tertiary;
      text-align: right;
      z-index: 1;
      
  
      .bgimg{
        background-image: url("../imgs/womanmeeting.jpg");
        filter: blur(0px);

        &:after{
          content: '';
          backdrop-filter: blur(10px);
          mask-image: linear-gradient(90deg, transparent 0%, transparent 35%, #000000 50%);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }

      &.mobile{
        .bgimg{
          background-image: url("../imgs/womanmeeting.jpg");
          filter: blur(0px);
  
          &:after{
            content: '';
            backdrop-filter: blur(5px);
            mask-image: none;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
          }
        }
      }
  
      .botao{
        background: @HomeDeveloper-tertiary;
        padding: 10px 30px;
        font-size: 18pt;
        text-shadow: none;
        margin-top: 20px;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 5px 5px 0px #0000005e;
        color: @HomeDeveloper-secondary-text;
        text-decoration: none;
        cursor: pointer;
        
        &:link, &:visited{
          text-decoration: none;
          color: @HomeDeveloper-secondary-text;
        }
      }
      .detail{
        padding-right: 40px !important;
      }

      h1{
        font-size: max(5vh, 3vw) !important;
        margin-bottom: 1vh;
      }
      h2{
        font-size: max(3vh, 2vw) !important;
        margin-bottom: 1vh;
      }
      p{
        font-size: clamp(14px, 1.2vw, 18px);
      }
    }

    .about{
      z-index: 2;
      background-color: #0b1433;
      background-image: linear-gradient(90deg,#1f1f1f, #242424);

      .row{
        min-height: 20vh;
      }

      .bgimg{
        position: absolute;
        background-image: url("../imgs/logo-hd.svg");
        background-size: 194px 231px;
        background-position: 50% 50%;
        filter: blur(0px);
        animation: aumentaBrilho 2s forwards 2s;
        height: 231px;
        width: 194px;
        top: 50%;
        right: 4vw;
        z-index: 0;
        transform: translateY(-50%) scaleX(-1);
      }

      .bgimg::after{
        content: '';
        position: absolute;
        background-image: url("../imgs/logo-hd.svg");
        background-size: 194px 231px;
        background-position: 50% 100%;
        filter: blur(0px);
        opacity: 0;
        animation: aumentaOpacidade 2s forwards 2s;
        height: 231px;
        width: 194px;
        transform: scaleY(-0.5) matrix(1,0,-.5,.9,55,-325);
        z-index: 0;
        background-repeat: no-repeat;
        -webkit-mask-image: linear-gradient(transparent 50%, rgba(255, 255, 255, 0.466) 100%);
        mask-image: linear-gradient(transparent 80%, rgba(255, 255, 255, 0.466) 100%);
      }

      @media (min-width: 1200px) {
        
        @height-imagem-1200: 462px;
        @width-imagem-1200: 388px;

        .bgimg{
          background-size: @width-imagem-1200 @height-imagem-1200;
          height: @height-imagem-1200;
          width: @width-imagem-1200;
        }
        
        .bgimg::after{
          background-size: @width-imagem-1200 @height-imagem-1200;
          height: @height-imagem-1200;
          width: @width-imagem-1200;
          transform: scaleY(-0.5) matrix(1,0,-.5,.9,110,-650);
        }

      }

      @media (min-width: 750px) and (max-width: 1199px) {

        @height-imagem-900: 297px;
        @width-imagem-900: 250px;

        .bgimg{
          background-size: @width-imagem-900 @height-imagem-900;
          height: @height-imagem-900;
          width: @width-imagem-900;
        }
        
        .bgimg::after{
          background-size: @width-imagem-900 @height-imagem-900;
          height: @height-imagem-900;
          width: @width-imagem-900;
          transform: scaleY(-0.5) matrix(1, 0, -0.5, 0.9, 73, -425);
        }
      }
      
      .detail{
        position: relative;
        padding: 1vh 2vw;
        top: 50%;
        transform: translateY(-50%);
        text-shadow:  3px 3px @HomeDeveloper-tertiary;

        h1{
          font-size: clamp(18px, 2.4vw, 30px);
          margin-bottom: 1vh;
        }
        h2{
          font-size: clamp(16px, 1.8vw, 25px);
          margin-bottom: 1vh;
        }
        p{
          font-size: clamp(14px, 1.2vw, 18px);
        }
      }

      &.mobile{

        .titulo-sobre{
          font-family: 'Oswald';
          font-size: max(10vh, 5vw);
          text-align: center;
          width: 100%;
          display: block;
          margin-bottom: 5vh;
        }

        .bgimg{
          top: 80%;
          right: 50%;
          transform: translate3d(50%, -50%,0) scaleX(-1);
        }
      }

      @media (max-height: 800px){
        &.mobile{
          min-height: 160%;
        }
      }

      @media (min-height: 801px){
        &.mobile{
          min-height: 125%;
        }
      }
  
    }

    
    .servicos{
      z-index: 3;

      .bgimg{
        position: absolute;
        background-image: url("../imgs/servicos.jpg");
        filter: blur(3px);

        &:after{
          content: '';
          background-color: rgba(0,0,0,0.6);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }

      .detail{
        position: relative;
        padding: 1vh 1vw;
        top: 50%;
        transform: translateY(-50%);
        text-shadow:  3px 3px @HomeDeveloper-tertiary;

        .titulo-servicos{
          font-family: 'Oswald';
          font-size: max(10vh, 5vw);
          text-align: center;
          width: 100%;
          display: block;
          margin-bottom: 5vh;
        }

        .servico{

          position: relative;
          display: grid;
          grid-template-areas: "div svg";
          grid-template-columns: auto 8vw;

          svg{
            position: relative;
            height: 5vh;
            margin: 0px auto;
            top: 50%;
            transform: translateY(-50%);
            animation: aumentaBrilho 2s forwards 2s;
          }

          p, h3{
            text-align: right;
          }
        }

        h3{
          font-size: clamp(16px, 1.5vw, 25px);
          margin-bottom: 0vh;
        }
        p{
          font-size: clamp(14px, 1.2vw, 18px);
        }
      }

      &.mobile{
        min-height: 170%;

        .row{
          min-height: 20vh;
        }

        .servico{
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-bottom: 3vh;

          svg{
            height: 5vh;
          }
          p,h3{
            text-align: center;
          }
        }
      }

      @media (max-height: 800px){
        &.mobile{
          min-height: 190%;
        }
      }

      @media (min-height: 801px){
        &.mobile{
          min-height: 160%;
        }
      }
    }

    .valores{
      z-index: 4;

      .row{
        min-height: 10vh;
      }

      .bgimg{
        position: absolute;
        background-color: rgb(58, 58, 58);
        filter: blur(4px);

        &:after{
          content: '';
          background-image: linear-gradient(90deg, rgba(0,0,0,0.78) 0%, rgba(0,0,0,0.78) 60%);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }

      .detail{
        position: relative;
        padding: 1vh 1vw;
        top: 50%;
        transform: translateY(-50%);
        text-shadow:  3px 3px @HomeDeveloper-tertiary;

        &.titulo-valor{
          padding: 0;
  
          span{
            font-family: 'Oswald';
            font-size: max(8vh, 4vw);
            text-align: center;
            width: 100%;
            display: block;
          }
        }

        .valor{

          position: relative;
          display: flex;
          flex-direction: column;
          margin:4vh;

          svg{
            position: relative;
            height: 5vh;
            margin: 0px auto;
            top: 50%;
            transform: translateY(-50%);
            animation: aumentaBrilho 2s forwards 2s;
          }

          p, h3{
            text-align: center;
          }
        }
      }

      @media (max-height: 800px){
        
        h3{
          font-size: clamp(16px, 1.8vw, 25px);
          margin-bottom: 1vh;
        }
        p{
          font-size: clamp(10px, 1.2vw, 14px);
        }

        &.mobile{
          min-height: 210%;

          
          h3{
            font-size: clamp(16px, 1.8vw, 25px);
            margin-bottom: 1vh;
          }
          p{
            font-size: clamp(14px, 1.2vw, 18px);
          }
        }
      }

      @media (min-height: 801px){
        

        h3{
          font-size: clamp(16px, 1.8vw, 25px);
          margin-bottom: 1vh;
        }
        p{
          font-size: clamp(14px, 1.2vw, 18px);
        }
        &.mobile{
          min-height: 190%;
        }
      }
    }

    .contact{
      z-index: 5;
      background: @HomeDeveloper-about;
      box-shadow: 0px 0px 20px black;

      &.mobile{
        min-height: 140%;
      }

      .bgimg{
        background-image: url("../imgs/contact-us.jpg");
        filter: blur(3px);
        
        &:after{
          content: '';
          background: #00000079;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
        }
      }
  
      .detail{
        position: relative;
        padding: 1vh 2vw;
        top: 50%;
        transform: translateY(-50%);
        text-shadow:  3px 3px @HomeDeveloper-tertiary;

        textarea {
          resize: none;
        }

        &.sugestao{
          text-align: right;
          h1{
            font-size: 6vh;
            font-weight: 600;
          }
          h2{
            font-size: 5vh;
            font-weight: 500;
          }
          p{
            font-size: 3vh;
            font-weight: 400;
          }
        }
      }
      
      .modal-content{
        background:rgb(58, 58, 58);
        border: rgb(233,233,233) 1px solid;

        .modal-header{
          button{
            filter: invert(1);
          }
        }

        .modal-footer{
          border: none;
        }
      }

      &.mobile{
        

        .sugestao{
          p, h1, h2{
            text-align: center !important;
          }
        }

        .bgimg{
          background-image: url("../imgs/contact-us.jpg");
          background-position: 85% 50%;
          filter: blur(3px);
          
          &:after{
            content: '';
            background: #00000079;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
          }
        }
      }
  
    }

    .footer{
      z-index: 6;
      background-color: #000000;
      
      .col{
        max-width: 33%;
        .logo{
          position: relative;
          text-align: center;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          flex-direction: column;
          img{
            width: 254px;
            margin-left: auto;
            margin-right: auto;            
            transform: translateX(3px);
          }
          .nome-empresa{
            font-family: 'Oswald';
            color: white;
            font-size: 43px;
          }
        }
      }

      .menu-horizontal{
        display: flex;
        flex-direction: column;

        position: relative;
        top: 50%;
        transform: translateY(-50%);

        a {
          height: 50px;
          line-height: 50px;
          padding: 0px;
          text-align: right;
          font-size: 16pt;
          transition: text-shadow .25s ease;
          text-decoration-color: transparent;
          font-weight: 700;
        }

        a:hover{
          text-shadow: 0px 0px 5px rgb(253, 255, 141);
          cursor: pointer;
        }
      }

      .dados{
        display: flex;
        flex-direction: column;

        position: relative;
        top: 50%;
        transform: translateY(-50%);
        font-size: 13pt;
      }
  
      .botao{
        background: @HomeDeveloper-tertiary;
        padding: 10px 30px;
        font-size: 18pt;
        text-shadow: none;
        margin-top: 20px;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 5px 5px 0px #0000005e;
        text-decoration: none;
        color: @HomeDeveloper-primary-text;
        
        &:link{
          color: @HomeDeveloper-primary-text;
        }
      }
  
      &.mobile{
        min-height: 150%;

        a{
          text-align: center;
        }

        .dados{
          text-align: center;
        }

        .logo{
          flex-direction: column;

          img{
            width: 50%;
            margin-right: auto;
            margin-left: auto;
            display: block;
          }

          .nome-empresa{
            display: block;
            text-align: center;
            margin-top: 5px;
            font-size: 25px;
          }
        }
      }

    }
  }

  .show-flex{
    display: flex;
  }

  .show-hidden{
    display: none;
  }

}
