@font-face {
  font-family: 'Avenir Black';
  src: local('Avenir Black'), url('../fonts/Avenir-Black.otf') format('opentype');
}
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url('../fonts/Oswald-Variable.ttf') format('truetype');
}
@font-face {
  font-family: 'Avenir Book';
  src: local('Avenir Book'), url('../fonts/Avenir-Book.otf') format('opentype');
}
@font-face {
  font-family: 'Avenir Roman';
  src: local('Avenir Roman'), url('../fonts/Avenir-Roman.otf') format('opentype');
}
@keyframes aumentaBrilho {
  0% {
    filter: blur(0px) drop-shadow(0px 0px 0px #fff7c8);
  }
  100% {
    filter: blur(0px) drop-shadow(0px 0px 6px #fff7c8);
  }
}
@keyframes aumentaOpacidade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.HomeDeveloper {
  min-height: 100%;
  font-family: 'Avenir Roman';
  font-weight: 500;
}
.HomeDeveloper .bgimg {
  position: absolute;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  /* Full height */
  height: 110%;
  width: 110%;
  transform: translate3d(-5%, -5%, 0);
  z-index: -1;
  top: 0px;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120% auto;
}
.HomeDeveloper .mobile .bgimg {
  background-size: auto 120%;
}
.HomeDeveloper .block {
  padding-top: 12vh;
  padding-bottom: 2vh;
  box-shadow: 0px 0px 20px black;
}
.HomeDeveloper .block .block-content .subblock h1 {
  text-transform: uppercase;
}
.HomeDeveloper .HomeDeveloper-header {
  position: fixed;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 10vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 9998;
  transition: top ease 1s, height 1s ease;
}
.HomeDeveloper .HomeDeveloper-header .logo {
  padding: 1vh;
  position: absolute;
  left: 2.5%;
  display: flex;
}
.HomeDeveloper .HomeDeveloper-header .logo img {
  height: 8vh;
  transform: translateY(0vh);
}
.HomeDeveloper .HomeDeveloper-header .logo span {
  color: #e9e9e9;
  line-height: 8vh;
  font-weight: 506;
  font-size: 1.3em;
  margin-left: 10px;
  font-family: "Oswald";
  color: white;
}
.HomeDeveloper .HomeDeveloper-header nav {
  position: absolute;
  right: 2.5%;
  top: 0;
  display: flex;
}
.HomeDeveloper .HomeDeveloper-header nav a,
.HomeDeveloper .HomeDeveloper-header nav span {
  color: #e9e9e9;
  margin: 0px 10px;
  line-height: 10vh;
  font-size: large;
  font-weight: 500;
  transition: text-shadow 0.25s ease;
  text-decoration-color: transparent;
}
.HomeDeveloper .HomeDeveloper-header nav a:hover,
.HomeDeveloper .HomeDeveloper-header nav span:hover {
  text-shadow: 0px 0px 5px #fdff8d;
  color: #e9e9e9;
  cursor: pointer;
}
.HomeDeveloper .HomeDeveloper-header.mobile {
  right: 0;
  overflow: hidden;
}
.HomeDeveloper .HomeDeveloper-header.mobile.open {
  height: 100%;
}
.HomeDeveloper .HomeDeveloper-header.mobile nav {
  right: 0;
  padding: 0 2.5%;
}
.HomeDeveloper .HomeDeveloper-header.mobile nav.menu {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10vh;
}
.HomeDeveloper .HomeDeveloper-header.mobile nav.menu a,
.HomeDeveloper .HomeDeveloper-header.mobile nav.menu span {
  padding-left: 5vw;
  height: 7vh;
  line-height: 7vh;
  text-align: center;
}
.HomeDeveloper .HomeDeveloper-header.esconder {
  top: -20vh;
}
.HomeDeveloper .content {
  min-height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #e9e9e9;
}
.HomeDeveloper .content .block {
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 700px) {
  .HomeDeveloper .content .block {
    min-height: 100%;
    max-height: 200%;
  }
}
.HomeDeveloper .content .block .row {
  min-height: 100%;
  max-height: 100%;
}
@media (max-width: 700px) {
  .HomeDeveloper .content .block .row {
    min-height: 100%;
    max-height: 200%;
  }
}
.HomeDeveloper .content .block .row .subblock {
  min-height: 100%;
  max-height: 100%;
}
.HomeDeveloper .content .block .row .subblock .detail {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}
.HomeDeveloper .content .block .row .subblock .detail h1 {
  font-size: clamp(18px, 2.4vw, 30px);
  margin-bottom: 1vh;
}
.HomeDeveloper .content .block .row .subblock .detail h2 {
  font-size: clamp(16px, 1.8vw, 25px);
  margin-bottom: 1vh;
}
.HomeDeveloper .content .block .row .subblock .detail p {
  font-size: clamp(14px, 1.2vw, 18px);
}
.HomeDeveloper .content .intro {
  text-shadow: 3px 3px #131d3db6;
  text-align: right;
  z-index: 1;
}
.HomeDeveloper .content .intro .bgimg {
  background-image: url("../imgs/womanmeeting.jpg");
  filter: blur(0px);
}
.HomeDeveloper .content .intro .bgimg:after {
  content: '';
  backdrop-filter: blur(10px);
  mask-image: linear-gradient(90deg, transparent 0%, transparent 35%, #000000 50%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.HomeDeveloper .content .intro.mobile .bgimg {
  background-image: url("../imgs/womanmeeting.jpg");
  filter: blur(0px);
}
.HomeDeveloper .content .intro.mobile .bgimg:after {
  content: '';
  backdrop-filter: blur(5px);
  mask-image: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.HomeDeveloper .content .intro .botao {
  background: #131d3db6;
  padding: 10px 30px;
  font-size: 18pt;
  text-shadow: none;
  margin-top: 20px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 5px 0px #0000005e;
  color: #e9e9e9;
  text-decoration: none;
  cursor: pointer;
}
.HomeDeveloper .content .intro .botao:link,
.HomeDeveloper .content .intro .botao:visited {
  text-decoration: none;
  color: #e9e9e9;
}
.HomeDeveloper .content .intro .detail {
  padding-right: 40px !important;
}
.HomeDeveloper .content .intro h1 {
  font-size: max(5vh, 3vw) !important;
  margin-bottom: 1vh;
}
.HomeDeveloper .content .intro h2 {
  font-size: max(3vh, 2vw) !important;
  margin-bottom: 1vh;
}
.HomeDeveloper .content .intro p {
  font-size: clamp(14px, 1.2vw, 18px);
}
.HomeDeveloper .content .about {
  z-index: 2;
  background-color: #0b1433;
  background-image: linear-gradient(90deg, #1f1f1f, #242424);
}
.HomeDeveloper .content .about .row {
  min-height: 20vh;
}
.HomeDeveloper .content .about .bgimg {
  position: absolute;
  background-image: url("../imgs/logo-hd.svg");
  background-size: 194px 231px;
  background-position: 50% 50%;
  filter: blur(0px);
  animation: aumentaBrilho 2s forwards 2s;
  height: 231px;
  width: 194px;
  top: 50%;
  right: 4vw;
  z-index: 0;
  transform: translateY(-50%) scaleX(-1);
}
.HomeDeveloper .content .about .bgimg::after {
  content: '';
  position: absolute;
  background-image: url("../imgs/logo-hd.svg");
  background-size: 194px 231px;
  background-position: 50% 100%;
  filter: blur(0px);
  opacity: 0;
  animation: aumentaOpacidade 2s forwards 2s;
  height: 231px;
  width: 194px;
  transform: scaleY(-0.5) matrix(1, 0, -0.5, 0.9, 55, -325);
  z-index: 0;
  background-repeat: no-repeat;
  -webkit-mask-image: linear-gradient(transparent 50%, rgba(255, 255, 255, 0.466) 100%);
  mask-image: linear-gradient(transparent 80%, rgba(255, 255, 255, 0.466) 100%);
}
@media (min-width: 1200px) {
  .HomeDeveloper .content .about .bgimg {
    background-size: 388px 462px;
    height: 462px;
    width: 388px;
  }
  .HomeDeveloper .content .about .bgimg::after {
    background-size: 388px 462px;
    height: 462px;
    width: 388px;
    transform: scaleY(-0.5) matrix(1, 0, -0.5, 0.9, 110, -650);
  }
}
@media (min-width: 750px) and (max-width: 1199px) {
  .HomeDeveloper .content .about .bgimg {
    background-size: 250px 297px;
    height: 297px;
    width: 250px;
  }
  .HomeDeveloper .content .about .bgimg::after {
    background-size: 250px 297px;
    height: 297px;
    width: 250px;
    transform: scaleY(-0.5) matrix(1, 0, -0.5, 0.9, 73, -425);
  }
}
.HomeDeveloper .content .about .detail {
  position: relative;
  padding: 1vh 2vw;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 3px 3px #131d3db6;
}
.HomeDeveloper .content .about .detail h1 {
  font-size: clamp(18px, 2.4vw, 30px);
  margin-bottom: 1vh;
}
.HomeDeveloper .content .about .detail h2 {
  font-size: clamp(16px, 1.8vw, 25px);
  margin-bottom: 1vh;
}
.HomeDeveloper .content .about .detail p {
  font-size: clamp(14px, 1.2vw, 18px);
}
.HomeDeveloper .content .about.mobile .titulo-sobre {
  font-family: 'Oswald';
  font-size: max(10vh, 5vw);
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 5vh;
}
.HomeDeveloper .content .about.mobile .bgimg {
  top: 80%;
  right: 50%;
  transform: translate3d(50%, -50%, 0) scaleX(-1);
}
@media (max-height: 800px) {
  .HomeDeveloper .content .about.mobile {
    min-height: 160%;
  }
}
@media (min-height: 801px) {
  .HomeDeveloper .content .about.mobile {
    min-height: 125%;
  }
}
.HomeDeveloper .content .servicos {
  z-index: 3;
}
.HomeDeveloper .content .servicos .bgimg {
  position: absolute;
  background-image: url("../imgs/servicos.jpg");
  filter: blur(3px);
}
.HomeDeveloper .content .servicos .bgimg:after {
  content: '';
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.HomeDeveloper .content .servicos .detail {
  position: relative;
  padding: 1vh 1vw;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 3px 3px #131d3db6;
}
.HomeDeveloper .content .servicos .detail .titulo-servicos {
  font-family: 'Oswald';
  font-size: max(10vh, 5vw);
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 5vh;
}
.HomeDeveloper .content .servicos .detail .servico {
  position: relative;
  display: grid;
  grid-template-areas: "div svg";
  grid-template-columns: auto 8vw;
}
.HomeDeveloper .content .servicos .detail .servico svg {
  position: relative;
  height: 5vh;
  margin: 0px auto;
  top: 50%;
  transform: translateY(-50%);
  animation: aumentaBrilho 2s forwards 2s;
}
.HomeDeveloper .content .servicos .detail .servico p,
.HomeDeveloper .content .servicos .detail .servico h3 {
  text-align: right;
}
.HomeDeveloper .content .servicos .detail h3 {
  font-size: clamp(16px, 1.5vw, 25px);
  margin-bottom: 0vh;
}
.HomeDeveloper .content .servicos .detail p {
  font-size: clamp(14px, 1.2vw, 18px);
}
.HomeDeveloper .content .servicos.mobile {
  min-height: 170%;
}
.HomeDeveloper .content .servicos.mobile .row {
  min-height: 20vh;
}
.HomeDeveloper .content .servicos.mobile .servico {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 3vh;
}
.HomeDeveloper .content .servicos.mobile .servico svg {
  height: 5vh;
}
.HomeDeveloper .content .servicos.mobile .servico p,
.HomeDeveloper .content .servicos.mobile .servico h3 {
  text-align: center;
}
@media (max-height: 800px) {
  .HomeDeveloper .content .servicos.mobile {
    min-height: 190%;
  }
}
@media (min-height: 801px) {
  .HomeDeveloper .content .servicos.mobile {
    min-height: 160%;
  }
}
.HomeDeveloper .content .valores {
  z-index: 4;
}
.HomeDeveloper .content .valores .row {
  min-height: 10vh;
}
.HomeDeveloper .content .valores .bgimg {
  position: absolute;
  background-color: #3a3a3a;
  filter: blur(4px);
}
.HomeDeveloper .content .valores .bgimg:after {
  content: '';
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.78) 0%, rgba(0, 0, 0, 0.78) 60%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.HomeDeveloper .content .valores .detail {
  position: relative;
  padding: 1vh 1vw;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 3px 3px #131d3db6;
}
.HomeDeveloper .content .valores .detail.titulo-valor {
  padding: 0;
}
.HomeDeveloper .content .valores .detail.titulo-valor span {
  font-family: 'Oswald';
  font-size: max(8vh, 4vw);
  text-align: center;
  width: 100%;
  display: block;
}
.HomeDeveloper .content .valores .detail .valor {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 4vh;
}
.HomeDeveloper .content .valores .detail .valor svg {
  position: relative;
  height: 5vh;
  margin: 0px auto;
  top: 50%;
  transform: translateY(-50%);
  animation: aumentaBrilho 2s forwards 2s;
}
.HomeDeveloper .content .valores .detail .valor p,
.HomeDeveloper .content .valores .detail .valor h3 {
  text-align: center;
}
@media (max-height: 800px) {
  .HomeDeveloper .content .valores h3 {
    font-size: clamp(16px, 1.8vw, 25px);
    margin-bottom: 1vh;
  }
  .HomeDeveloper .content .valores p {
    font-size: clamp(10px, 1.2vw, 14px);
  }
  .HomeDeveloper .content .valores.mobile {
    min-height: 210%;
  }
  .HomeDeveloper .content .valores.mobile h3 {
    font-size: clamp(16px, 1.8vw, 25px);
    margin-bottom: 1vh;
  }
  .HomeDeveloper .content .valores.mobile p {
    font-size: clamp(14px, 1.2vw, 18px);
  }
}
@media (min-height: 801px) {
  .HomeDeveloper .content .valores h3 {
    font-size: clamp(16px, 1.8vw, 25px);
    margin-bottom: 1vh;
  }
  .HomeDeveloper .content .valores p {
    font-size: clamp(14px, 1.2vw, 18px);
  }
  .HomeDeveloper .content .valores.mobile {
    min-height: 190%;
  }
}
.HomeDeveloper .content .contact {
  z-index: 5;
  background: black;
  box-shadow: 0px 0px 20px black;
}
.HomeDeveloper .content .contact.mobile {
  min-height: 140%;
}
.HomeDeveloper .content .contact .bgimg {
  background-image: url("../imgs/contact-us.jpg");
  filter: blur(3px);
}
.HomeDeveloper .content .contact .bgimg:after {
  content: '';
  background: #00000079;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.HomeDeveloper .content .contact .detail {
  position: relative;
  padding: 1vh 2vw;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 3px 3px #131d3db6;
}
.HomeDeveloper .content .contact .detail textarea {
  resize: none;
}
.HomeDeveloper .content .contact .detail.sugestao {
  text-align: right;
}
.HomeDeveloper .content .contact .detail.sugestao h1 {
  font-size: 6vh;
  font-weight: 600;
}
.HomeDeveloper .content .contact .detail.sugestao h2 {
  font-size: 5vh;
  font-weight: 500;
}
.HomeDeveloper .content .contact .detail.sugestao p {
  font-size: 3vh;
  font-weight: 400;
}
.HomeDeveloper .content .contact .modal-content {
  background: #3a3a3a;
  border: #e9e9e9 1px solid;
}
.HomeDeveloper .content .contact .modal-content .modal-header button {
  filter: invert(1);
}
.HomeDeveloper .content .contact .modal-content .modal-footer {
  border: none;
}
.HomeDeveloper .content .contact.mobile .sugestao p,
.HomeDeveloper .content .contact.mobile .sugestao h1,
.HomeDeveloper .content .contact.mobile .sugestao h2 {
  text-align: center !important;
}
.HomeDeveloper .content .contact.mobile .bgimg {
  background-image: url("../imgs/contact-us.jpg");
  background-position: 85% 50%;
  filter: blur(3px);
}
.HomeDeveloper .content .contact.mobile .bgimg:after {
  content: '';
  background: #00000079;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.HomeDeveloper .content .footer {
  z-index: 6;
  background-color: #000000;
}
.HomeDeveloper .content .footer .col {
  max-width: 33%;
}
.HomeDeveloper .content .footer .col .logo {
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}
.HomeDeveloper .content .footer .col .logo img {
  width: 254px;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(3px);
}
.HomeDeveloper .content .footer .col .logo .nome-empresa {
  font-family: 'Oswald';
  color: white;
  font-size: 43px;
}
.HomeDeveloper .content .footer .menu-horizontal {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.HomeDeveloper .content .footer .menu-horizontal a {
  height: 50px;
  line-height: 50px;
  padding: 0px;
  text-align: right;
  font-size: 16pt;
  transition: text-shadow 0.25s ease;
  text-decoration-color: transparent;
  font-weight: 700;
}
.HomeDeveloper .content .footer .menu-horizontal a:hover {
  text-shadow: 0px 0px 5px #fdff8d;
  cursor: pointer;
}
.HomeDeveloper .content .footer .dados {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13pt;
}
.HomeDeveloper .content .footer .botao {
  background: #131d3db6;
  padding: 10px 30px;
  font-size: 18pt;
  text-shadow: none;
  margin-top: 20px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 5px 0px #0000005e;
  text-decoration: none;
  color: #4568dc;
}
.HomeDeveloper .content .footer .botao:link {
  color: #4568dc;
}
.HomeDeveloper .content .footer.mobile {
  min-height: 150%;
}
.HomeDeveloper .content .footer.mobile a {
  text-align: center;
}
.HomeDeveloper .content .footer.mobile .dados {
  text-align: center;
}
.HomeDeveloper .content .footer.mobile .logo {
  flex-direction: column;
}
.HomeDeveloper .content .footer.mobile .logo img {
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
.HomeDeveloper .content .footer.mobile .logo .nome-empresa {
  display: block;
  text-align: center;
  margin-top: 5px;
  font-size: 25px;
}
.HomeDeveloper .show-flex {
  display: flex;
}
.HomeDeveloper .show-hidden {
  display: none;
}
